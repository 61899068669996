<template>
    <Loading :isLoading="isLoading" />
    <div class="flex ml-5 mt-5 cursor-pointer" @click="redirect_history()" >
        <img src="@/assets/back-arrow.svg">Atrás
    </div>
    <div class="bg-white m-0 px-5 md:px-3 md:m-5 ">
        <div class="grid grid-cols-1 md:grid-cols-2 px-0 md:px-0 lg:px-20 mt-10">

            <div class="w-full md:w-4/5 md:mx-auto mt-5">
                <p class="text-3xl font-bold roboto-regular mb-4">{{ questionTitle }}</p>
                <QuotationCoverageComponent
                    ref="ComponentCoverageRef"
                    :validations="validations"
                    :view_class_view="view_class_view" 
                    :buttons_styles="buttons_styles"
                    :coverage_list="coverageList"
                />

                <!-- <div class="w-full md:w-4/5 mt-5">
                    <div class="py-2" v-show="list_get_dependents.length>0">
                        <label class="text-2xl"> DEPENDIENTES </label>
                    </div>
                    <div v-for="(dependent, index) in list_get_dependents" :key="index" class="border-2 py-2 my-5 px-5 w-full rounded-lg">
                        <div>Nombres: {{  get_full_name(dependent) }}</div>
                        <div>Edad: {{ (dependent.age > 1 ? dependent.age + ' Años' : dependent.age + ' Año') }} </div>
                        <div>Parentesco: {{ dependent.relationship }}</div>
                    </div>

                </div> -->
                
                <div class="w-full flex justify-center">
                   <input type="button" id="button_next" :disabled="!isActive" :class="{'button-disabled cursor-default': !isActive, 'bg-mustardDark cursor-pointer': isActive}" class="w-full text-white px-5 py-2 mx-auto my-4 h-14 md:h-14  rounded text-center font-medium text-xl" @click="next()" value="Continuar">        
                </div>
            </div>
            <div class="w-full bg-white hidden md:block md:py-14">
                <img src="@/assets/Cuenta/vidaSimple/coverages.svg" :alt="questionTitle" class="mx-auto" >
            </div>
        </div> 
    </div>
</template>

<script>
import { ref, onMounted, onBeforeMount, getCurrentInstance } from 'vue';
import { helpers, requiredIf } from '@vuelidate/validators';

import Loading from '@/components/Loading/VueLoading.vue';
import { useRouter, useRoute } from 'vue-router';
import CatalogCoverage from '@/classes/VidaSimpleCatalogCoverage.js';
import moment from 'moment';
import Quote from '@/classes/Quotation.js';
import { availableFormStorage, getLocalStorage, getSessionStorage, createLocalStorage } from '@/helpers/storage.js';

export default {
    name: "Coverage",
    setup(){
        const router = useRouter();
        const route = useRoute();
        const token = ref(null)
        const CatalogCoverageClass = ref(null);
        const questionTitle = ref('Coberturas');
        const isLoading = ref(true);
        const ComponentCoverageRef = ref(null);
        const category = ref("");
        const quoteClass = ref(null);
        const localStorage_quoteClass = ref({});
        const list_get_dependents = ref([]);
        const user_information = ref({});

        let personalRfc = ref('');
        let personalBirthday = ref('');
        let isActive = ref(false);
        let app = getCurrentInstance();
        let validations = {
            amount: {
                required: helpers.withMessage('El campo es requerido', requiredIf(function(){
                    return true;
                })),
                minValue: helpers.withMessage('La suma asegurada debe ser mayor o igual a $50,000.00.', function(value){
                    let amountInsured = Number(value);
                    if(amountInsured >= 50000){
                        return true;
                    }
                    return false;
                }),
                maxValue: helpers.withMessage('La suma asegurada debe ser menor o igual a $2,000,000.00.', function(value){
                    let amountInsured = Number(value);
                    if(amountInsured <= 2000000){
                        return true;
                    }
                    return false;
                }),
                numberFormat: helpers.withMessage('Después del punto solo se admiten dos decimales', function (data, index) {
                    return new RegExp(/^[0-9]+([.][0-9]{1,2})?$/).test(index.amount.toString());
                }),
                $lazy: true
            },
            coverage: {
                required: helpers.withMessage('El campo es requerido', requiredIf(function(){
                    return false;
                })),
                benefitsConditionals: helpers.withMessage('Solo es posible elegir uno de los beneficios MA, DI o TI.', function(values, index){
                    
                    let benefitsConditionals = ['MA', 'DI', 'TI'];
                    let benefits = values.filter(value =>  {
                        if(benefitsConditionals.includes(value.toString().toUpperCase())){
                            return value
                        }
                    });
                    return (benefits.length>1)? false: true;
                })
            }
        };

        let coverageList = ref([]);

        const view_class_view = {
            label: "",
            label_input_checkbox: "",
            class_inputs:{
                valid:"border-blue-600",
                no_valid:"border-red-600",
                default:"w-full py-2 px-2 rounded-md border-2 border-sm focus:border-blue-600 mb-2 mt-2 shadow-md focus:outline-none "
            },
            div_container_error:{
                container:"my-2",
                text_error:"text-red-600",
            },
            div_container:"grid grid-cols-1 sm:gap-5",
        };

        const buttons_styles = {
            buttonSelected:"#feb72b",
            activeColorBackground: "#feb72b",
            inactiveColorBackground: "",
            activeColorText: "#ffffff",
            inactiveColorText: "",
            activeBorderColor: "",
            inactiveBorderColor: ""
        };

        onBeforeMount(async() => {
            CatalogCoverageClass.value = new CatalogCoverage();
            getAccountInfo();
        });

        onMounted(async () => {
            quoteClass.value = new Quote();
            category.value = getCategory();

            getInformationClient();
            await getCoverageList();
            
            isLoading.value = false;
            isActive.value = true;
            app = app.appContext.config.globalProperties;
        
            const quote = quoteClass.value.get(category.value);
            if(quote.is_additional_insured == "Para Adicional")
            {
                configurationForAditional();
            }

            loadFormStorage();
            ComponentCoverageRef.value.coverageModelValidations.$reset()
        });

        const getInformationClient = () => {
            localStorage_quoteClass.value = getQuoteFromStorage()
            if(localStorage_quoteClass.value.is_additional_insured == "Para socio")
                list_get_dependents.value = quoteClass.value.get_dependents();
            user_information.value = JSON.parse(sessionStorage.getItem("user-information"));
        }

        const configurationForAditional = () => { 
            setTitle();
        }

        const loadFormStorage = () => {
            try{
                if(!availableFormStorage('quote-coverage')) return;
                
                const data = getLocalStorage('quote-coverage');

                ComponentCoverageRef.value.coverageModel.amount = data.amount;

                data.coverage.forEach(element => {
                    checkboxActivate(element);
                });

            }catch(error){
                console.log("error:", error)
            }
        }

        const checkboxActivate = (inputName) => {
            const input = document.getElementById(`list_coverage_${inputName}`);
            input.click();
        }

        const setTitle = () => {
            questionTitle.value += " para adicional";
        }

        const saveData = (values) => {
            const category = getCategory(false);
            const nameStorage = `quote-${category}`;
            
            let quote = getLocalStorage(nameStorage);
            quote.assured_amount = parseInt(values.amount);
            quote.coverages = values.coverage;
            quote.dependent_life_insurance = { aquired:"No", dependents_list:[] } 
            if(values.coverage.includes("DF"))
            quote.dependent_life_insurance = { aquired:"SI", dependents_list:list_get_dependents.value }
            if(category == 'vida-total' && !quote.coverages.includes('defunción')) quote.coverages.push('defunción');
            if(category == 'vida-simple' && !quote.coverages.includes('basic')) quote.coverages.push('basic');
            
            createLocalStorage(nameStorage, quote)
        }

        const getQuoteFromStorage = () => {
            const product = getCategory(false);
            return getLocalStorage(`quote-${product}`);
        }

        const getCategory = (replace = true) => {
            let product = route.params.product;
            return replace? product.replaceAll("-", " "): product;
        }

        const getAccountInfo = () => {
            token.value = getToken();
            let userInformation = getSessionStorage('user-information');
            personalRfc.value = userInformation.user_data.rfc;
            personalBirthday.value = userInformation.user_data.birthday;
        }

        const getCoverageListVt = () => {
            const coverageList = [
                {
                    active: true,
                    class_name: "invalidez",
                    created_at: "2022-09-05T22:36:57.491Z",
                    description: "cobertura invalidez",
                    id: 2,
                    name: "invalidez",
                    updated_at: "2022-09-05T22:36:57.491Z",
                },/* ,
                {
                    active: true,
                    class_name: "invalidez_sin_espera",
                    created_at: "2022-09-05T22:36:57.491Z",
                    description: "cobertura invalidez sin espera",
                    id: 3,
                    name: "invalidez sin espera",
                    updated_at: "2022-09-05T22:36:57.491Z",
                },
                {
                    active: true,
                    class_name: "doble_indemnizacion_por_beneficio_de_accidente",
                    created_at: "2022-09-05T22:36:57.491Z",
                    description: "cobertura doble indemnización por beneficio de accidente",
                    id: 4,
                    name: "doble indemnizacion por beneficio de accidente",
                    updated_at: "2022-09-05T22:36:57.491Z",
                } */
            ];

            return {status: true, data: coverageList, message: ""};
        }

        const getCoverageList = async () => {
            const category = getCategory(false);
            
            let result = category == 'vida-simple'? await getCoverage(): getCoverageListVt();
            if(result.status){
                coverageList.value = getFinalArrayCoverage(result.data);
                // if(localStorage_quoteClass.value.is_additional_insured == "Para socio" && list_get_dependents.value.length > 0)
                // coverageList.value.push({value:`DEFUNCIÓN PARA ${list_get_dependents.value.length} ${ list_get_dependents.value.length == 1 ? 'DEPENDIENTE' : "DEPENDIENTES"}`, text: `DEFUNCIÓN PARA ${list_get_dependents.value.length} ${ list_get_dependents.value.length == 1 ? 'DEPENDIENTE' : "DEPENDIENTES"}`});
            }
        }

        const getFinalArrayCoverage = (values) => {
            let age =  getAge();
            let coverages = values.filter(element => element.class_name != 'basic');
            coverages = coverages.map(element => {
                if(element.name.toLowerCase() == "invalidez" && age > 59 && category.value == "vida total"){
                    return {value: element.class_name, text: `${element.description.toString().toUpperCase()}`, disabled: true, information_class_view: "text-sm ml-2 text-red-600 font-medium", information_text: "(No aplica por edad mayor a 59 años)"}
                }
                return {value: element.class_name, text: `${element.description.toString().toUpperCase()}`, disabled: false, information_class_view: "", information_text: ""}
            });

            const order = ["ma", "di", "ti"];
            return coverages.sort((a, b) => order.indexOf(a.value) - order.indexOf(b.value));
        }

        const getCoverage = async () => {
            let age =  getAge();
            return await CatalogCoverageClass.value.getList({productName: 'vida simple', age: age}).then(resp => {
                return { status: true, data: resp.data.response.data, message: resp.data.response.message }
            }).catch((err) => { 
                let error = (err.response && err.response.data)? err.response.data.response.message: err.message;
                return { status: false, data: [], message: error }
            });
        }

        const getToken = () => {
            return sessionStorage.getItem('login');
        }

        const getAge = () => {
            let quoteJson = getQuoteFromStorage();
            if(!quoteJson) return; 
            return getAgeByDate(quoteJson.person.birthday)
        }

        const getAgeByDate = (date) => {
            let birthday = moment(date);
            let today = moment();
            let age = today.diff(birthday,"years");
            return age;
        }

        const next = async () => {
            const ComponentCoverageRefs = ComponentCoverageRef.value.execute_validation();
            if(!ComponentCoverageRefs) return false ;
            createLocalStorage('quote-coverage', ComponentCoverageRefs);
            saveData(ComponentCoverageRefs);
            router.push('detalle')
        }

        function get_full_name(person)
        {
            return [person["name"],person["middle_name"],person["paternal_name"],person["maternal_name"]].filter(x=>x).join(" ");
        }

        const redirect_history = () => router.go(-1);

        return { 
            validations,
            view_class_view,
            buttons_styles,
            coverageList,
            isActive,
            questionTitle,
            next,
            isLoading,
            ComponentCoverageRef,
            list_get_dependents,
            get_full_name,
            redirect_history
        }
    },
    components:{
        Loading
    }

}
</script>

<style>
.question-container > div > div {
    justify-content: space-between;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 15px;
}

.question-container > div > div > button{
    border-radius:5px;
    width:49%;
}

.question-container>div>label, .question-container>div>input, .question-container>div>button {
     font-family: "roboto-regular";
}

.question-container > div > div > button.active {
    background-color: #810042;
    color: white;
    border-color: #810042;
}

.question-container > div > div > button {
    box-sizing: border-box;
    background: #D9D9D9;
    color: white;
    border-radius: 5px;
    font-size: 18px;
    margin-inline: 1px;
}

.question-container>div>label {
    font-size: 18px;
    line-height: 30px;
    color: #485462;
}

.card-shadow {
  box-shadow: 0px 4px 6px -3px rgb(178 176 176);
  -webkit-box-shadow: 0px 4px 6px -3px rgb(178 176 176);
  -moz-box-shadow: 0px 4px 6px -3px rgb(178 176 176);
}

.content_textarea_0 {
    margin-top: 20px;
}

.content_date_3, .content_date_4 {
    margin-top: 9px;
}

.vpd-input-group input {
    flex-grow: 1;
    border: 2px solid #e5e7eb;
    border-right: 2px solid #e5e7eb;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    line-height: 30px;
    padding: 0 10px;
    height: 43px;
}

.content_textarea_6 {
    display:flex;
}

.content_checkbox_1 > div {
    width:100%!important;
}


@media only screen and (min-width: 600px) {
    .content_date_3, .content_date_4 {
        display: block;
    }
}

@media only screen and (max-width: 600px) {
    .content_date_3, .content_date_4 {
        display: flex;
    }
}

#input_rfc_contracting_data_1,
#input_curp_contracting_data_2{
    text-transform: uppercase;
}
</style>
<style scoped>
.button-disabled {
    background-color: #EDD191;
}



@media only screen and (min-width: 600px) {
    .detail-container {
        background-color: #FAFAFA;
    }
    .content_date_3, .content_date_4 {
        display: block;
    }
}
@media only screen and (max-width: 600px) {
    .bg-container {
        background-color: #FCFCFC;
    } 
    .content_date_3, .content_date_4 {
        display: flex;
    }
}
button {
    font-size: 19px!important;
}

</style>