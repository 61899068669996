import api from '@/api/api';
import { getToken } from '@/helpers/tokenauth.js'; 

const host = process.env.VUE_APP_GSAPI;

export default class CatalogCoverage {

    active = true;
    class_name = "";
    created_at =  "";
    description = "";
    id = 0
    name = "";
    updated_at = "";
    
    async getList({productName, age}) {
        
        try {
            if(this.isNullOrEmpty(productName)) throw "El nombre de producto no puede estar vacío o nulo";
            if(this.isNullOrEmpty(age)) throw "La edad no puede estar vacía o nula";

            const resource = `/products/get_available_coverages_by_product_and_age`;
            const API_URL = `${host}${resource}`;
            const headers = {
                "Content-Type": "application/json",
                "Authorization": `Token ${getToken()}`
            };

            const params = {
                product_name: productName,
                age: age
            };

            return await api.httpGet(API_URL, headers, params);
        } catch(err){
            throw new Error(err);
        }
    }

    isNullOrEmpty(value)  {
        return value === null || value === undefined || value.length === 0;
    }             

}